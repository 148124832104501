<template>
  <v-container class="custom-container">
    <v-row class="custom-row">
      <div class="background-col" :style="{ background: backgroundColor }"></div>

      <v-col class="text-col">
        <v-row class="title-row">
          <v-col>
            <v-text class="title-text mr-4">{{ title1 }}</v-text>
          </v-col>
        </v-row>

        <v-row class="title-row">
          <v-col>
            <v-text class="subtitle-text">{{ title2 }}</v-text>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  props: {
    backgroundColor: String,
    title1: String,
    title2: String,
  },
};
</script>

<style scoped>
.custom-container {
  height: 60px;
  padding: 0;
  background-color: #f7f2f2 !important;
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
}

.custom-row {
  height: 100%;
}

.background-col {
  width: 10px;
}

.text-col {
  width: 140px;
  height: 100%;
}

.title-row {
  height: 50%;
}

.title-text {
  font-size: 12px;
  color: #333;
  font-weight: 600;
}

.subtitle-text {
  font-size: 18px !important;
}
</style>
